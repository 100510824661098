import {Rate,Tag,Select,Dialog,Option,Input,Button,Pagination,Form,FormItem,DatePicker,Table,TableColumn,Image,Upload,Radio,RadioGroup } from 'element-ui';
import {getList,submitReview} from '../api/student-task-status';
import { showErrorTip } from '../util';
import user from  '../js/user';
import Recorder from 'js-audio-recorder';
import axios from 'axios';
let recorder = new Recorder();
export default {
  name:'index',
  components:{
    elUpload:Upload,
    elRate:Rate,
    elImage:Image,
    elDialog:Dialog,
    elTag:Tag,
    elSelect:Select,
    elOption:Option,
    elInput:Input,
    elButton:Button,
    elPagination:Pagination,
    elForm:Form,
    elFormItem:FormItem,
    elDatePicker:DatePicker,
    elTable:Table,
    elTableColumn:TableColumn,
    elRadioGroup:RadioGroup,
    elRadio:Radio,
  },
  data(){
    this.limit=20;
    this.formLabelWidth = '120px';
    this.id = this.$route.params.id;
    this.statusmap={
      'to_be_modified':'待修改',
      'pass':'通过',
    }
    return {
      isRecording:false,
      user:user,
      type:'review',// review | seeReview | modify
      studentId:'',
      dialogVisible:false,
      disabled:false,
      rules:{
        text:[
          {
            required: true, message: '请输入评语', trigger: ['blur']
          }
        ],
        status:[
          {
            required: true, message: '请选择状态', trigger: ['blur']
          }
        ]
      },
      form:{
        audio:'',
        image:[],
        text:'',
        rate:0,
        status:''
      },
      statusEnum:[
        {
          label:'进行中',
          value:'1'
        },
        {
          label:'待开班',
          value:'2'
        },
      ],
      productsEnum:[
        {
          label:'入门课',
          value:'1'
        },
        {
          label:'进阶课',
          value:'2'
        },
      ],
      list:[
        // {
        //   id:1,
        //   index:1,
        //   name:'入门课',
        //   time:'2020-09-08 08:00:00',
        //   status:'to_be_modified',
        //   evaluation_level:-1,
        //   evaluation_image:['/data/upload/cover/9205c37774f96609fcb55a7d9fd17776.png','/data/upload/cover/9205c37774f96609fcb55a7d9fd17776.png']
        // }
      ]
    }
  },
  async mounted(){
    // const res = await getBanner();
    // if(res.error !== null){
    //   showErrorTip(res.data);
    // }else{
    //   this.banners = res.data;
    // }
    this.getList();
    // this.getConfig();
  },
  methods:{
    uploadfile(file){
      const forms = new FormData()
      const configs = {
        headers:{'Content-Type':'multipart/form-data'}
      };
      forms.append('file',file);
      return new Promise((resolve,reject)=>{
        axios.post('/api/GenericUploadAPI',forms,configs)
        .then(res=>{
          resolve(res.data);
        })
        .catch(error=>{
          reject(error)
        })
      })
      
    },
    onModify(){
      this.type='modify'
    },
    deleteAudio(){
      this.form.audio = '';
    },
    async onClickRecord(){
      if(this.isRecording){
        recorder.stop();
        this.isRecording = false;
        const res = await this.uploadfile(recorder.getWAVBlob());
        if(res.error === null){
          this.form.audio = res.data.path;
        }else{
          showErrorTip(res.data);
        }
        
      }else{
        Recorder.getPermission().then(() => {
          recorder.start().then(()=>{
            this.isRecording = true;
          },(error=>{
            showErrorTip(`${error.name} : ${error.message}`)
          }));
        }, (error) => {
          showErrorTip(`${error.name} : ${error.message}`)
        });
        
      }
    },
    beforeDestroy(){
      recorder.destroy().then(function() {
        recorder = null;
      });
    },
    onDeleteImg(index){
      const image = [...this.form.image];
      image.splice(index,1);
      this.form.image = image;
    },
    async getList(){
      const res = await getList({
        task_id:this.id
      }); 
      this.list = res.data;
    },
    onReview(studentId){
      this.studentId = studentId;
      this.type = 'review';
      this.dialogVisible = true;
    },
    onSeeReview(data){
      this.studentId = data.id;
      this.form = {
        image:data.evaluation_images,
        audio:data.evaluation_audio,
        text:data.teacher_evaluation,
        rate:data.evaluation_level,
        status:data.evaluation_status
      };
      this.type = 'seeReview';
      this.dialogVisible = true;
    },
    pageTodetail(item){
      const submissionid = item.id;
      const taskid = item.task.id;
      window.open(`../scratch3/seetask.html?submissionid=${submissionid}&taskid=${taskid}`);
    },
    handleImgUploadSuccess(res){
      const {path} = res.data;
      const arr = [...this.form.image];
      arr.push(path)
      this.form.image = arr;
    },
    handleAudioUploadSuccess(res){
      this.form.audio = res.data.path;
    },
    handleUploadError(error){
      showErrorTip(error)
    },
    oncancel(){
      this.form = {
        image:[],
        audio:'',
        text:'',
        rate:0,
        status:''
      }
      this.$refs.reviewForm.clearValidate();
      this.dialogVisible = false;
    },
    onconfirm(){
      this.$refs.reviewForm.validate(async (valid)=>{
        if(valid){
          const {text,rate,image,audio,status} = this.form;
          const res = await submitReview({
            submission_id:this.studentId,
            evaluation:text,
            level:rate,
            images:image,
            audio,
            status,
          })
          if(!res.error){
            this.dialogVisible = false;
            this.getList();
          }
        }else{
          return false;
        }
      })
     
    },
  }
}