import {get,post} from '../util/request.js';
import {showErrorTip} from '../util/index.js'
// 获取学生作业完成情况列表
const getList = async (data) => {
  const res = await get({
    url:'/api/TeacherCourseTaskSubmissionAPI',
    data
  });
  if(res.error){
    showErrorTip(res.data);
  }
  return res;
}
// 老师评价一个提交
const submitReview = async (data) => {
  const res = await post({
    url:'/api/TeacherCourseTaskSubmissionAPI',
    data
  });
  if(res.error){
    showErrorTip(res.data);
  }
  return res;
}

export {
  getList,
  submitReview
}